@mixin object-fit($width:100%, $height:100%) {
	&:not(html):not(body) {
		object-fit: cover;
		object-position: center center;
		width:$width;
		height:$height;
	}
	&:is(picture) > img {
		object-fit: cover;
		object-position: center center;
		width:$width;
		height:$height;
	}
}

.object-fit {
	@include object-fit();
}