@use "sass:math";

@function parseInt($n) {
	@return math.div($n, $n * 0 + 1);
}

@mixin font-size($values) {
	$px : ();
	$rem: ();
	
	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);	 
			$val: parseInt($value);
		
			@if $unit == "px" { 
				$px : append($px,  $value);
				$rem: append($rem, ($val * 0.1 + rem));
			}
		
			@if $unit == "rem" {
				$px : append($px,  ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {		  
		font-size: $px; 
	} @else {
		font-size: $px; 
		font-size: $rem;
	}
}