// A darn fine way to center something
@mixin centerer() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin centererX() {
	left: 50%;
	transform: translateX(-50%);
}
@mixin centererY() {
	top: 50%;
	transform: translateY(-50%);
}