/*-----------*/
/* Variables
/*-----------*/

:root {
	//--header-height: 95px;
}

//Global Gri, variables
$global-config:	(
	block--width:	100%,
	width:			1600px,
	columns:		12,
	gutters:		15px,
);

$logo-height: (
	xs:  40px,
	sm:  65px,
	md:  65px,
	lg:  65px,
	xl:  65px,
	xl2: 65px,
	xl3: 65px,
);

$nav-width: (
	xs:  90vw,
	sm:  90vw,
	md:  70vw,
	lg:  35vw,
	xl:  35vw,
	xl2: 35vw,
	xl3: 35vw,
);

$grid-breakpoints: (
	xs:  0,
	sm:  544px,
	md:  768px,
	lg:  992px,
	xl:  1200px,
	xl2: 1440px,
	xl3: 1600px,
);

//Colors
$colors: (
	true-black:		#000000,
	black:			#21212b,
	white:			#fefeff,
	firebrick:		#bc2329,
	charcoal:		#4d4d4d,
	ultralight:		#f9f7f7,
	champagne:		#FED18C,
	blue:       	#1b849d,
	olive:			#8CB369,
	orange:			#ff8922,
	orange-bright:  #ee6f00,
	gray:			#8C93A8,
	gainsboro:		#F8FFE5,
	transparent:	transparent,	
);

//Z-Index Management -- utilizes Sass Maps
//Easiest way to use is via the mixin: @include z-index(zero);
$z-index: (
	heaven					:	9000,
	hamburger				:	4000,
	header					:	3500,
	site-nav				:	3000,
	social-share			:	2000,
	header--logo			:	1000,
	aboveground				:	100,
	grass					:	2,
	ground					:	1,
	zero					:	0,
	underground				:	-1,
	hell					:	-9000
);

$transitions: (
	ease			: 	ease,
	linear			: 	linear,
	ease-in			: 	ease-in,
	ease-out		: 	ease-out,
	ease-in-out		: 	ease-in-out,
	step-start		: 	step-start,
	step-end		: 	step-end,

	// Special Effects
	bounce			:	cubic-bezier(0.68, -0.55, 0.265, 1.55),

	// Cubic
	easeInCubic		:	cubic-bezier(0.550, 0.055, 0.675, 0.190),
	easeOutCubic 	:	cubic-bezier(0.215, 0.610, 0.355, 1.000),
	easeInOutCubic	:	cubic-bezier(0.645, 0.045, 0.355, 1.000),

	// Circ
	easeInCirc   	:	cubic-bezier(0.600, 0.040, 0.980, 0.335),
	easeOutCirc  	:	cubic-bezier(0.075, 0.820, 0.165, 1.000),
	easeInOutCirc	:	cubic-bezier(0.785, 0.135, 0.150, 0.860),

	// Expo
	easeInExpo   	:	cubic-bezier(0.950, 0.050, 0.795, 0.035),
	easeOutExpo  	:	cubic-bezier(0.190, 1.000, 0.220, 1.000),
	easeInOutExpo	:	cubic-bezier(1.000, 0.000, 0.000, 1.000),

	// Quad
	easeInQuad     	:	cubic-bezier(0.550, 0.085, 0.680, 0.530),
	easeOutQuad    	:	cubic-bezier(0.250, 0.460, 0.450, 0.940),
	easeInOutQuad  	:	cubic-bezier(0.455, 0.030, 0.515, 0.955),

	// Quart
	easeInQuart    	:	cubic-bezier(0.895, 0.030, 0.685, 0.220),
	easeOutQuart   	:	cubic-bezier(0.165, 0.840, 0.440, 1.000),
	easeInOutQuart 	:	cubic-bezier(0.770, 0.000, 0.175, 1.000),

	// Quint
	easeInQuint    	:	cubic-bezier(0.755, 0.050, 0.855, 0.060),
	easeOutQuint   	:	cubic-bezier(0.230, 1.000, 0.320, 1.000),
	easeInOutQuint 	:	cubic-bezier(0.860, 0.000, 0.070, 1.000),

	// Sine
	easeInSine     	:	cubic-bezier(0.470, 0.000, 0.745, 0.715),
	easeOutSine    	:	cubic-bezier(0.390, 0.575, 0.565, 1.000),
	easeInOutSine  	:	cubic-bezier(0.445, 0.050, 0.550, 0.950),

	// Back
	easeInBack     	:	cubic-bezier(0.600, -0.280, 0.735, 0.045),
	easeOutBack    	:	cubic-bezier(0.175,  0.885, 0.320, 1.275),
	easeInOutBack  	:	cubic-bezier(0.680, -0.550, 0.265, 1.550),
);