/*--------------*/
/* Typography
/*--------------*/

$font-family-headline:		'Montserrat', sans-serif;
$font-family-sans-serif:	'Montserrat', sans-serif;

@mixin font-body($color: color(charcoal), $sm-font: 18px, $xl2-font: 19px) {
	font-family: $font-family-sans-serif;
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), $sm-font, $xl2-font);
	font-weight: 300;
	line-height: 1.9;
	@if ($color != inherit) {
	color: $color;
	}

	a:not([class]) {
		@include color(blue);
		
		@include hover() {
			color: darken(color(blue), 20);
			text-decoration: underline;
		}
	}
	a[class] {
		text-decoration: none;
	}
}


@mixin font-navigation($color: color(white), $sm-font: 34px, $xl2-font: 54px) {
	font-family: $font-family-headline;
	color: $color;
	text-transform: uppercase;
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), $sm-font, $xl2-font);
	font-weight: 600;
	letter-spacing: 0.05em;
	text-decoration: none;
	white-space: nowrap;
	opacity: 100%;
	transition: all ease 0.3s;

	@include hover() {
		text-decoration: none;
		opacity: 40%;
		transition: all ease 0.3s;
		@include color(white);
	}
}

@mixin font-hamburger($sm-font: 16px, $xl2-font: 17px) {
	font-family: $font-family-headline;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.05em;
	text-decoration: none;
	white-space: nowrap;
	opacity: 100%;
	transition: all ease 0.3s;

	@include hover() {
		text-decoration: none;
		opacity: 40%;
		transition: all ease 0.3s;
		@include color(blue);
	}
}

@mixin font-small-allcaps($color: color(charcoal)) {
	font-family: $font-family-sans-serif;
	@if ($color != inherit) {
	color: $color;
	}
	@include font-size(12px);
	letter-spacing: 0.1em;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
	
	a {
		@if ($color != inherit) {
		color: $color;
		}transition: opacity ease 0.3s;
		text-decoration: none;
	
		@include hover() {
			transition: opacity ease 0.3s;
		}
	}
}

@mixin font-tiny($color: color(charcoal), $opacity: 1) {
	font-family: $font-family-sans-serif;
	@if ($color != inherit) {
	color: rgba($color, $opacity);
	}
	@include font-size(13px);
	letter-spacing: 0.5px;
	font-weight: 400;
	text-decoration: none;
	line-height: 1.5;
	
	a {
		@if ($color != inherit) {
		color: rgba($color, $opacity);
		}
		transition: opacity ease 0.3s;
		text-decoration: none;
	
		@include hover() {
			transition: opacity ease 0.3s;
			text-decoration: underline;
		}
	}
}

@mixin font-btn($color: color(white)) {
	font-family: $font-family-headline;
	@if ($color != inherit) {
	color: $color;
	}
	@include font-size(14px);
	text-transform: uppercase; 
	letter-spacing: 0.5px;
	font-weight: 600;
	text-decoration: none;
	line-height: 1.5;
}

@mixin font-headline($color: color(charcoal), $sm-font: 34px, $xl2-font: 64px) {
	font-family: $font-family-headline;
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), $sm-font, $xl2-font);
    font-weight: 600;
	letter-spacing: 0rem;
	line-height: 0.9em;
	text-decoration: none;
	@if ($color != inherit) {
	color: $color;
	text-transform: uppercase;
	}
}
@mixin font-headline-small($color: color(charcoal)) {
	@include font-headline($color, 24px, 30px);
}
@mixin font-section-header($color: color(blue)) {
	@include font-headline($color, 30px, 40px);
	text-align: center;
	width: 100%;
}

@mixin font-section-header-small($color: color(charcoal)) {
	@include font-headline($color, 25px, 30px);
	text-align: center;
	width: 100%;
}

@mixin font-business-details($color: color(charcoal)) {
	@include font-headline($color, 25px, 30px);
	text-align: center;
	width: 100%;
}

@mixin font-mobile-nav($color: color(blue)) {
	@include font-headline($color, 20px, 30px);
}


body {
	@include font-body();
}

p,
ul,
ol {
	@include font-body();
	margin: 0 0 15px;
	
	&:empty {
		display: none;
	}

	&:last-child {
		margin-bottom: 0;
	}
}


h1, h2, h3, h4, h5, h6 {
	line-height: 1.5;
	margin: 0;
	@include color(charcoal);
}

hr {
	border: transparent;
	height: 1px;
	@include color(gray, background);
	margin-bottom: 20px;
}

a {
	transition: all ease-in-out 300ms;

	&::before,
	&::after {
		display: inline-block;
	}
	@include hover() {
		transition: all ease-in-out 300ms;
	}
}

sup {
	font-size: 60%;
}