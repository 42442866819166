//Good way to hide something but have it still be accessible to screen-readers
@mixin visuallyhidden {
	position: absolute;
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

%visuallyhidden {
	@include visuallyhidden();
}

.visuallyhidden {
	@include visuallyhidden();
}