//Colors
@function color($color, $opacity: 1) {
	@if ($opacity != 1) {
	@return rgba(map-get($colors, $color), $opacity);
	} @else {
	@return map-get($colors, $color);
	}
}

@mixin color($color, $property-name: color, $opacity: 1) {
	@if ($opacity != 1) {
	#{$property_name}: rgba(color($color), $opacity);
	} @else {
	#{$property_name}: color($color);
	}
}