@font-face {
    font-family: "iconfont";
    src: url('../../assets/media/fonts/iconfont/iconfont.eot?erd6s9');
    src: url('../../assets/media/fonts/iconfont/iconfont.eot?erd6s9#iefix') format('eot'),
      url('../../assets/media/fonts/iconfont/iconfont.woff2?erd6s9') format('woff2'),
      url('../../assets/media/fonts/iconfont/iconfont.woff?erd6s9') format('woff'),
      url('../../assets/media/fonts/iconfont/iconfont.ttf?erd6s9') format('truetype'),
      url('../../assets/media/fonts/iconfont/iconfont.svg?erd6s9#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

$font-iconfont:	"iconfont";

$icon-arrow-left: "\EA01";
$icon-arrow-right: "\EA02";
$icon-browser: "\EA03";
$icon-business: "\EA04";
$icon-caret-down: "\EA05";
$icon-caret-left: "\EA06";
$icon-caret-right: "\EA07";
$icon-caret-up: "\EA08";
$icon-check: "\EA09";
$icon-chevron-down: "\EA0A";
$icon-chevron-left: "\EA0B";
$icon-chevron-right: "\EA0C";
$icon-chevron-up: "\EA0D";
$icon-circle-empty: "\EA0E";
$icon-circle-full: "\EA0F";
$icon-circle: "\EA10";
$icon-close: "\EA11";
$icon-document: "\EA12";
$icon-dog-cabin: "\EA13";
$icon-dog-house-1: "\EA14";
$icon-dog-house-2: "\EA15";
$icon-dog-house-3: "\EA16";
$icon-dog-house-4: "\EA17";
$icon-dog-house-5: "\EA18";
$icon-dog-tent: "\EA19";
$icon-dot-ring: "\EA1A";
$icon-dots: "\EA1B";
$icon-email: "\EA1C";
$icon-facebook: "\EA1D";
$icon-fax: "\EA1E";
$icon-grid: "\EA1F";
$icon-halftone-dots: "\EA20";
$icon-home: "\EA21";
$icon-instagram: "\EA22";
$icon-linkedin: "\EA23";
$icon-logo-long-primary: "\EA24";
$icon-logo-long-secondary: "\EA25";
$icon-logo-long-white: "\EA26";
$icon-logo-long: "\EA27";
$icon-logo-square-primary: "\EA28";
$icon-logo-square-secondary: "\EA29";
$icon-logo-square-white: "\EA2A";
$icon-logo-square: "\EA2B";
$icon-mail: "\EA2C";
$icon-map: "\EA2D";
$icon-menu: "\EA2E";
$icon-midcoast: "\EA2F";
$icon-minus: "\EA30";
$icon-paw: "\EA31";
$icon-pdf-report: "\EA32";
$icon-person: "\EA33";
$icon-phone-alt: "\EA34";
$icon-phone: "\EA35";
$icon-pinterest: "\EA36";
$icon-play: "\EA37";
$icon-play2: "\EA38";
$icon-plus: "\EA39";
$icon-quote-end: "\EA3A";
$icon-quote-start: "\EA3B";
$icon-reset: "\EA3C";
$icon-snapchat: "\EA3D";
$icon-square: "\EA3E";
$icon-squiggle: "\EA3F";
$icon-tiktok: "\EA40";
$icon-triangle: "\EA41";
$icon-twitter: "\EA42";
$icon-vimeo: "\EA43";
$icon-website: "\EA44";
$icon-yelp: "\EA45";
$icon-youtube: "\EA46";
$icon-zigzag: "\EA47";