// There's a lot of different clearfix hacks out there on the web. This one (http://nicolasgallagher.com/micro-clearfix-hack) I've found it to be the most solid one. Works in IE6 and up.
@mixin clearfix {
	*zoom: 1;
	&::before,
	&::after {
		content: " ";
		display: table;
	}
	&::after {
		clear: both;
	}
}

%clearfix {
	@include clearfix();
}
.clearfix {
	@include clearfix();
}