@function unicode($str) {
	@return unquote("\"") + $str + unquote("\"");
  }

//Iconfont
@mixin iconfont($content: " ", $font-size: inherit, $color: inherit ) {
	font-family: $font-iconfont;
	speak: none;
	font-style: normal;
	font-weight: 100;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	content: unicode($content);
	font-size: $font-size;
	color: $color;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}