@import "../../node_modules/normalize.scss/normalize.scss";

// Variables & Mixins
@import 'base/_variables.scss';
@import 'base/_mixins.scss';

// Partials
@import 'partials/iconfont';
@import 'partials/typography';
@import 'partials/buttons';

html {
	box-sizing: border-box;
	font-size:62.5%; //62.5% is recommended to we can easily use REM values and generate a px fallback.
}
body {
    @include color(blue, background-color);
}
.login {
    h1 {
        position: relative;
        height: 140px;

        &::before {
            @include iconfont($icon-paw, 150px);
            @include centerer();
            @include color(white);
            @include z-index(ground);
        }
        &::after {
            content: '';
            @include centerer();
            display: block;
            width: 150px;
            height: 150px;
            @include z-index(zero);
        }

        a {
            display: none;
            background-image: none;
        }
    }


    label,
    #backtoblog,
    #nav {

        a {
            @include color(white);
    
            @include hover() {
                @include color(white, $opacity: 0.5);
            }
        }
    }
}

.login {
    form {
        background: rgba(color(charcoal), 0.6);
        border: 1px solid rgba(color(black), 0.1);
    }

    label,
    #backtoblog a,
    #nav a {
        @include color(white);
    }
}

.wp-core-ui .button-primary {
    @include color(blue, background-color);
    border: none;
    box-shadow: 0 0 0 0;
    text-shadow: none;
}

