/**********/
/* Buttons
/**********/


//Alternative mixin to Bootstrap button-varient mixin
@mixin new-button($color, $background, $border) {
	display: inline-block;
	@include font-btn($color);
	background-color: $background;
	background-repeat: no-repeat;
	background-position-x: -100px;
	border-width: 2px;
	border-style: solid;
	border-color: $border;
	border-radius: 5px;
	padding: 5px 10px;
	text-align: center;
	opacity: 1;
	@include transition(bounce);
	
	@include screen(lg) {
	    padding: 5px 20px;
	}
	
	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		text-decoration: none;
		color: $background !important;
		background-color: $color !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
	
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
		box-shadow: none;
		@include transition(bounce);
	}
	
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: lighten($background, 20);
			border-color: lighten($background, 20);
			cursor: not-allowed;
			
		}
 	}
	
	.badge {
		color: $background;
		background-color: $color;
	}
  
}



.btn {
	@include new-button( color(white), color(blue), color(blue));
}

.btn__header-book-now {
	@include new-button( color(white), color(blue), color(blue));
}

.btn__white {
	@include new-button( color(blue), color(white), color(white));
}

.btn__orange {
	@include new-button( color(white), color(orange), color(orange));

	background-image: linear-gradient(to left, rgba(color(orange),1) 0%,rgba(color(orange-bright),1) 100%);
}


.btn__block {
	display: block !important;
}


.btn__phone {
	position: relative;
	@include font-size(0px);
	width: 36px;
	height: 36px;
	@include color(blue, background-color);
	border: 2px solid color(blue);
	border-radius: 5px;
	margin: 0 gutters();

	&::after {
		@include centerer();
		@include iconfont($icon-phone, 20px, color(white));
	}

	@include hover() {
		color: color(blue) !important;
		background-color: color(white) !important;
		@include transition(bounce);

		&::after {
			@include color(blue);
		}
	}
}

.btn__small {
	padding: 5px 15px;
    @include font-size(13px);
    
}

.btn__transparent {
	@include new-button( color(blue), color(transparent), color(blue));

	@include hover() {
		color: color(white) !important;
		background-color: color(blue) !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
}

.btn__transparent__white {
	@include new-button( color(white), color(transparent), color(white));

	@include hover() {
		color: color(blue) !important;
		background-color: color(white) !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
}

.btn__large {
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), 14px, 22px);
	padding: 20px 40px;
}

.btn__huge {
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), 16px, 30px);
	padding: 20px 60px;
}


@mixin button-group($divider-color) {
	@include screen(xs, only) {
		display: flex;
		flex-direction: column;

		& > a  {
		//	border-radius: 30px;
			margin-bottom: 15px;
		}
	}
	
	& > a {
		position: relative;
		padding-left: 30px;
		padding-right: 30px;

		@include screen(sm) {
			border-radius: 0;

			&:nth-child(n+2) {
				&::before {
					content: '';
					position: absolute;
					left: -2px;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					display: block;
					width: 1px;
					height: 35%;
					background-color: $divider-color;
					@include transition(bounce);
				}
			}

			&:first-child {
				padding-left: 60px;
				border-top-left-radius: 30px;
				border-bottom-left-radius: 30px;
			}
	
			&:last-child {
				padding-right: 60px;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
			}
		}

		@include hover() {
			&::before {
				opacity: 0;
				@include transition(bounce);
			}
			& + a {
				&::before {
					opacity: 0;
					@include transition(bounce);
				}	
			}
		}
	}
}


.btn--video-trigger {
	width: 60px;
	height: 60px;
	@include color(white, background-color);
	border: none;
	border-radius: 999px;
	@include font-size(0);
	transition: background-color cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;
	@include z-index(homepage--hero--trigger);
	box-shadow: 0 0 0 rgba(color(black), 0.4);
	animation: pulse 2s infinite;

	@include screen(md) {
		width: 90px;
		height: 90px;
	}

	body[device=mobile] &,
	body[device=tablet] & {
		animation: none;
		box-shadow: 2px 2px 20px rgba(color(black), .5), -2px -2px 20px rgba(color(black), .5)
	}

	&::after {
		content:'';
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-left: 16px solid color(black);
		border-bottom: 8px solid transparent;
		@include centerer();
		@include color(black);

		@include screen(md) {
			border-top-width: 10px;
			border-left-width: 20px;
			border-bottom-width: 10px;
		}
	}

	@include hover() {
		body[device=desktop] & {
			cursor: pointer;
		}
	}
}