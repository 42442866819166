//Custom breakpoint mixin
@mixin screen($breakpoint: 'xs', $query: 'min', $min-width: NULL, $max-width: NULL) {
	@if (($min-width != NULL) or ($max-width != NULL)) {
		
		@if (($min-width != NULL) and ($max-width == NULL)) {
			@media only screen and (min-width: $min-width) {
				@content;
			}
		}
		
		@if (($min-width == NULL) and ($max-width != NULL)) {
			@media only screen and (max-width: $max-width) {
				@content;
			}
		}
		
		@if (($min-width != NULL) and ($max-width != NULL)) {
			@media only screen and (min-width: $min-width) and (max-width: $max-width) {
				@content;
			}
		}
		
		
	}
	@else {
		@if ($query == 'min') {		
			@media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
				@content;
			}
		}
		@if ($query == 'max') {
			@media only screen and (max-width: (map-get-next($grid-breakpoints, $breakpoint) - 1)) {
				@content;
			}
		}
		
		@if ($query == 'only') {
			@media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) and (max-width: (map-get-next($grid-breakpoints, $breakpoint) - 1)) {
				@content;
			}
		}
	}
}